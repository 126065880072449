import { faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './App.css';

function App() {
    return (
        <>

            <div className="banner-figure">
                <div className="header">
                    <div className="brand-name">
                        Spellbook
                        <div className="brand-logo"></div>
                    </div>
                    <div className="btn-sign-in">
                        Sign in<FontAwesomeIcon className="icon-sign-in" style={{"marginLeft": "6px"}} fontSize={18} icon={faArrowRightToBracket} />
                    </div>
                </div>
                <div className="banner-container">
                    <div className="banner-content">
                        <h1 className="h1">Data driven by <span className="text-magic">magic</span></h1>
                        <div className="h1-lead">Take control of structured data in modular and scalable platforms.</div>
                    </div>
                </div>
            </div>
            <div style={{height: "400px"}}>
                Site is currently under maintenance.
            </div>
            <footer className="footer">
                © Copyright 2023 Spellbook. All rights reserved.
            </footer>
        </>
    );
}

export default App;
